<div class="container footer text-muted">
  <div class="row">
    <div class="col">&copy; {{ year }} Vaultwarden.</div>
    <div class="col text-center"></div>
    <div class="col text-right">
      {{ "versionNumber" | i18n: version }}
      <br/>
      Mon Dec  5 02:42:16 UTC 2022
      <br/>
      1e517d1319ec5ec815c3c27be6f33b3ffb981fc5
    </div>
  </div>
</div>
